import {
  Organization,
  OrganizationConfig,
  OrganizationConfigData,
  OrganizationPayoutData,
  OrganizationPayoutResData,
  OrganizationResponse,
  OrganizationSelfDeclaration,
  OrganizationSelfDeclarationData,
} from '../organization';

export type OrganizationRequest = Partial<Omit<OrganizationResponse, 'external_id' | 'id' | 'user_id' | 'type'>>;
export type OrganizationUpdate = Partial<Omit<Organization, 'externalId' | 'id' | 'userId' | 'type'>>;

export const mapResponseOrganization = (organization: OrganizationResponse): Organization => {
  return {
    address: organization.address,
    city: organization.city,
    config: mapResponseConfig(organization.config),
    dailyReportEmail: organization.daily_report_email,
    email: organization.email,
    externalId: organization.external_id,
    id: organization.id,
    name: organization.name,
    nip: organization.nip,
    payoutData: mapResponseMultiPayout(organization.payout_data),
    phone: organization.phone,
    registrationDeadlineHours: organization.registration_deadline_hours,
    selfDeclaration: mapResponseSelfDeclaration(organization.self_declaration),
    type: organization.type,
    userId: organization.user_id,
  };
};

export const mapRequestOrganization = (organization: OrganizationUpdate): OrganizationRequest => {
  return {
    address: organization.address,
    city: organization.city,
    config: mapRequestConfig(organization.config),
    daily_report_email: organization.dailyReportEmail,
    email: organization.email,
    name: organization.name,
    nip: organization.nip,
    payout_data: mapRequestMultiPayout(organization.payoutData),
    phone: organization.phone,
    registration_deadline_hours: organization.registrationDeadlineHours,
    self_declaration: mapRequestSelfDeclaration(organization.selfDeclaration),
  };
};

const mapRequestConfig = (config?: OrganizationConfig | null): Partial<OrganizationConfigData> | null => {
  if (!config) {
    return null;
  }

  return {
    certificate_town: config.certificateTown,
  };
};

const mapRequestSelfDeclaration = (
  selfDeclaration?: OrganizationSelfDeclaration | null,
): Partial<OrganizationSelfDeclarationData> | null => {
  if (!selfDeclaration) {
    return null;
  }

  return {
    default: selfDeclaration?.default,
    substance_abuse: selfDeclaration?.substanceAbuse,
  };
};

const mapResponseSelfDeclaration = (
  selfDeclaration?: OrganizationSelfDeclarationData | null,
): Partial<OrganizationSelfDeclaration> | null => {
  if (!selfDeclaration) {
    return null;
  }

  return {
    default: selfDeclaration?.default,
    substanceAbuse: selfDeclaration?.substance_abuse,
  };
};

const mapRequestMultiPayout = (
  payoutData?: OrganizationPayoutData | null,
): Partial<OrganizationPayoutResData> | null => {
  if (!payoutData) {
    return null;
  }

  return {
    button_label: payoutData.buttonLabel,
    button_link: payoutData.buttonLink,
    success_url: payoutData.successUrl,
  };
};

const mapResponseConfig = (config: OrganizationConfigData | null): OrganizationConfig | null => {
  if (!config) {
    return null;
  }

  return {
    certificateTown: config.certificate_town,
  };
};
const mapResponseMultiPayout = (payoutData: OrganizationPayoutResData | null): OrganizationPayoutData | null => {
  if (!payoutData) {
    return null;
  }

  return {
    buttonLabel: payoutData.button_label,
    buttonLink: payoutData.button_link,
    successUrl: payoutData.success_url,
  };
};
