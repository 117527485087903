import { User, UserResponse } from '../user';
import { mapResponseOrganization } from './organization';

export type UserRequest = Pick<UserResponse, 'email' | 'first_name' | 'last_name' | 'role'>;

export type UserAddUpdateData = Omit<User, 'name'>;

export const mapResponseUser = (user: UserResponse): User => ({
  email: user.email,
  firstName: user.first_name,
  id: user.id,
  lastName: user.last_name,
  name: user.name,
  organization: user.organization ? mapResponseOrganization(user.organization) : undefined,
  phone: user.phone,
  role: user.role,
});

export const mapRequestUpdateAddUser = (data: UserAddUpdateData): UserRequest => ({
  email: data.email,
  first_name: data.firstName,
  last_name: data.lastName,
  role: data.role,
});
