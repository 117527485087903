import { NavItemProps } from './types';
import SchoolIcon from '@mui/icons-material/School';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupsIcon from '@mui/icons-material/Groups';
import Groups2Icon from '@mui/icons-material/Groups2';
import navigationItems from '@/routes/navigationItems';

const MenuItems: NavItemProps[] = [
  {
    icon: <SchoolIcon />,
    id: '1',
    label: navigationItems.HOME.title,
    link: navigationItems.HOME.path,
  },
  {
    icon: <Groups2Icon />,
    id: '2',
    label: navigationItems.PARTICIPANTS_ALL.title,
    link: navigationItems.PARTICIPANTS_ALL.path,
  },
  {
    icon: <CalendarMonthIcon />,
    id: '3',
    label: navigationItems.GET_CALENDAR.title,
    link: navigationItems.GET_CALENDAR.path,
  },
  {
    icon: <GroupsIcon />,
    id: '4',
    label: navigationItems.USERS.title,
    link: navigationItems.USERS.path,
  },
];
export default MenuItems;
