const navigationItems = {
  FORGOT_PASSWORD: { path: '/reset-password', title: 'Reset Hasła' },
  GET_CALENDAR: { path: '/get-calendar', title: 'Kalendarz na www' },
  HOME: { path: '/', title: 'Lista kursów' },
  LOGIN: { path: '/login', title: 'Logowanie' },
  LOGOUT: { path: '/logout', title: 'Wyloguj' },
  PARTICIPANTS: {
    getPath: (id?: number) => (id ? `/scheduled-events/${id}/participants` : '/scheduled-events/:id/participants'),
    path: '/scheduled-events/:id/participants',
    title: 'Lista kursantów',
  },
  PARTICIPANTS_ALL: { path: '/participants', title: 'Wszyscy kursanci' },
  PASSWORD_RESET: { path: '/password/reset', title: 'Reset hasła' },
  SCHEDULED_EVENTS: { path: '/scheduled-events', title: 'Lista kursów' },
  SETTINGS: { path: '/settings', title: 'Ustawienia' },
  USERS: { path: '/users', title: 'Użytkownicy' },
};
export default navigationItems;
