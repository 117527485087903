import { MenuItemProps } from './types';
import navigationItems from '@/routes/navigationItems';

const MenuItems: MenuItemProps[] = [
  {
    id: '1',
    label: navigationItems.SETTINGS.title,
    link: navigationItems.SETTINGS.path,
  },
  {
    id: '2',
    label: navigationItems.LOGOUT.title,
    link: navigationItems.LOGOUT.path,
  },
];
export default MenuItems;
