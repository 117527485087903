import Drawer from './Drawer';
import { Divider, Toolbar } from '@mui/material';
import Items from './Items';
import LogoCompact from '@/assets/logos/LogoCompact';
import LogoBig from '@/assets/logos/logoBig';

interface SidebarProps {
  open: boolean;
}

const Sidebar = ({ open }: SidebarProps) => {
  return (
    <Drawer variant='permanent' open={open} color='primary'>
      <Toolbar
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          px: [1],
        }}
      >
        {!open && <LogoCompact />}
        {open && <LogoBig />}
      </Toolbar>
      <Divider />
      <Items />
    </Drawer>
  );
};
export default Sidebar;
