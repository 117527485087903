import { api } from './index';
import { Organization, OrganizationResponse } from './organization';
import { createSelector } from '@reduxjs/toolkit';
import { mapResponseOrganization } from './mappers/organization';
import { mapRequestUpdateAddUser, mapResponseUser, UserAddUpdateData } from './mappers/user';

export interface User {
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  name: string;
  organization?: Organization;
  phone?: string;
  role: string;
}

export interface UserResponse {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  name: string;
  organization?: OrganizationResponse;
  phone?: string;
  role: string;
}

interface UsersMeta {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
}

export interface Users<T> {
  data: T[];
  meta: UsersMeta;
}

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addUser: builder.mutation<
      UserResponse,
      {
        data: UserAddUpdateData;
        organizationId: number;
      }
    >({
      invalidatesTags: ['Users'],
      query: (request) => {
        const requestData = mapRequestUpdateAddUser(request.data);
        return {
          body: requestData,
          method: 'POST',
          url: `/organization/${request.organizationId}/staff-members`,
        };
      },
    }),
    deleteUser: builder.mutation<
      void,
      {
        id: number;
        organizationId: number;
      }
    >({
      invalidatesTags: ['Users'],
      query: (request) => {
        return {
          method: 'DELETE',
          url: `/organization/${request.organizationId}/staff-members/${request.id}`,
        };
      },
    }),
    getUserDetails: builder.query<User, void>({
      providesTags: ['User'],
      // invalidatesTags: ['Organization'],
      query: () => ({
        url: '/profile',
      }),
      transformResponse: (response: { data: UserResponse }) => {
        return {
          email: response.data.email,
          firstName: response.data.first_name,
          id: response.data.id,
          lastName: response.data.last_name,
          name: response.data.name,
          organization: response.data?.organization ? mapResponseOrganization(response.data.organization) : undefined,
          phone: response.data.phone,
          role: response.data.role,
        };
      },
    }),
    getUsers: builder.query<
      Users<User>,
      {
        id: number;
        orderBy: string;
        page?: number;
      }
    >({
      providesTags: ['Users'],
      query: ({ id, orderBy, page = 1 }: { id: number; orderBy: string; page: number }) => ({
        url: `/organization/${id}/staff-members?page=${page}&sort=${orderBy}`,
      }),
      transformResponse: (response: Users<UserResponse>) => {
        const users = response.data.map(mapResponseUser);
        return {
          data: users,
          meta: response.meta,
        };
      },
    }),
    updateUser: builder.mutation<
      UserResponse,
      {
        data: UserAddUpdateData;
        organizationId: number;
      }
    >({
      invalidatesTags: ['Users'],
      query: (request) => {
        const requestData = mapRequestUpdateAddUser(request.data);
        return {
          body: requestData,
          method: 'PATCH',
          url: `/organization/${request.organizationId}/staff-members/${request.data.id}`,
        };
      },
    }),
  }),
});

// export const selectUserDetails = userApi.endpoints.getUserDetails.select();

export const selectUserDetails = createSelector(
  userApi.endpoints.getUserDetails.select(),
  (userResult) => userResult?.data ?? {},
);

export const selectUserOrganization = createSelector(
  userApi.endpoints.getUserDetails.select(),
  (userResult) => userResult?.data?.organization ?? undefined,
);

export const {
  useAddUserMutation,
  useUpdateUserMutation,
  useGetUserDetailsQuery,
  useGetUsersQuery,
  useDeleteUserMutation,
} = userApi;
