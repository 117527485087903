import { ListItemButton } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavItemProps } from './types';
import { Link } from 'react-router-dom';

const NavItem = ({ label, link, icon }: Omit<NavItemProps, 'id'>) => {
  return (
    <ListItemButton component={Link} to={link}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
};
export default NavItem;
