import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';

const LoaderContainer = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
});

const Loader = () => {
  return (
    <LoaderContainer>
      <CircularProgress />
    </LoaderContainer>
  );
};
export default Loader;
