import { IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { matchRoutes, useLocation } from 'react-router-dom';
import navigationItems from '@/routes/navigationItems';
import AccountMenu from './AccountMenu';
import { useMemo } from 'react';

interface ToolBarProps {
  open: boolean;
  toggleDrawer: () => void;
}

const ToolBar = ({ toggleDrawer, open }: ToolBarProps) => {
  const location = useLocation();

  const title = useMemo(() => {
    const routeMath = matchRoutes(Object.values(navigationItems), location);
    return routeMath?.[0].route.title || '';
  }, [location]);

  return (
    <Toolbar
      sx={{
        pr: '24px', // keep right padding when drawer closed
      }}
    >
      <IconButton
        edge='start'
        color='inherit'
        aria-label='open drawer'
        onClick={toggleDrawer}
        sx={{ marginRight: '36px' }}
      >
        {!open && <MenuIcon />}
        {open && <ChevronLeftIcon />}
      </IconButton>
      <Typography component='h1' variant='h6' color='inherit' noWrap sx={{ flexGrow: 1 }}>
        {title}
      </Typography>
      <AccountMenu />
    </Toolbar>
  );
};
export default ToolBar;
