import {
  BaseQueryApi,
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '@/store/store';
import { actions } from '@/store/auth/reducer';
import cacheManager from '@/utils/cache/cacheManager';
import { AUTH_SESSION_KEY, AuthResponse, RefreshTokenRequest } from '@/api/auth';
import { GrantType } from '@/api/types';
import { mapAuthResponse } from '@/api/mappers/auth';

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    console.log((getState() as RootState).auth);
    const token = (getState() as RootState).auth.accessToken;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const refreshResult = await refreshToken(api, extraOptions);
    if (refreshResult.data) {
      // store the new token
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const data = refreshResult.data.data as AuthResponse;
      api.dispatch(actions.loginSuccess(mapAuthResponse(data)));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      // Logout and clear data in storage
      api.dispatch(actions.logout());
      cacheManager.remove(AUTH_SESSION_KEY);
    }
  }
  return result;
};

const refreshToken = async (api: BaseQueryApi, extraOptions: object) => {
  // try to get a new token
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const refreshToken = api.getState().auth.refreshToken;
  return baseQuery(
    {
      body: { grant_type: GrantType.REFRESH_TOKEN, refresh_token: refreshToken } as RefreshTokenRequest,
      method: 'POST',
      url: '/login',
    },
    api,
    extraOptions,
  );
};

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: ['User', 'Organization', 'Event', 'Events', 'EventTypes', 'Participants', 'ParticipantsAll', 'Users'],
});
// eslint-disable-next-line
export const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError => 'status' in error;
