import { AlertColor } from '@mui/material';
import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const NOTIFICATION_TIMOUT = 5000;

export interface Notification {
  id?: string;
  message?: string;
  open?: boolean;
  timeout?: number | null;
  type?: AlertColor;
}

export type NotificationState = Notification[];

const initialState: NotificationState = [];

const slice = createSlice({
  initialState,
  name: 'notifications',
  reducers: {
    addNotification: (state: NotificationState, action: PayloadAction<Notification>) => [
      ...state,
      {
        ...action.payload,
        id: nanoid(5),
        open: true,
        timeout: NOTIFICATION_TIMOUT,
      },
    ],
    clearNotification: (state, action: PayloadAction<string>) => [
      ...state.filter((item) => item.id !== action.payload),
    ],
  },
});

export const selectNotifications = (state: RootState) => state.notifications;

const { name, reducer, actions } = slice;

export { name, actions, reducer };
