const LogoCompact = () => (
  <svg width='50' height='49' viewBox='0 0 50 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.41618 37.6706H0V49.0001H11.3295V47.584H1.41618V37.6706Z'
      fill='white'
    />
    <path fillRule='evenodd' clipRule='evenodd' d='M38 47.5839V49H49.3295V37.6706H47.9131V47.5839H38Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.3309 1.41621V3.05176e-05L0.00145531 3.05176e-05L0.00145531 11.3295H1.41764L1.41764 1.41621L11.3309 1.41621Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.9135 11.3295H49.3295V-9.53674e-06L38 -9.53674e-06V1.41615L47.9135 1.41615V11.3295Z'
      fill='white'
    />
    <path
      d='M26.8842 29.9664L31.0687 15H33.2453L32.4692 19.1676L27.9472 34.6571H25.8212L26.8842 29.9664ZM23.1046 15L26.4624 29.6795L27.171 34.6571H25.0282L20 15H23.1046ZM38.4085 29.6458L41.7324 15H44.8371L39.8089 34.6571H37.666L38.4085 29.6458ZM33.8527 15L37.9698 30.017L39.0159 34.6571H36.8899L32.5029 19.1676L31.7099 15H33.8527Z'
      fill='#FFCB08'
    />
    <path
      d='M12.8797 27.2667H7.27786V24.6851H12.8797C13.9146 24.6851 14.7526 24.5389 15.3938 24.2464C16.0349 23.9427 16.5074 23.5321 16.8111 23.0147C17.1148 22.4972 17.2667 21.9011 17.2667 21.2261C17.2667 20.585 17.1148 19.9888 16.8111 19.4376C16.5074 18.8864 16.0349 18.4421 15.3938 18.1046C14.7526 17.7559 13.9146 17.5816 12.8797 17.5816H8.12151V34.6571H5V15H12.8797C14.4883 15 15.8493 15.27 16.963 15.8099C18.0878 16.3386 18.9371 17.0698 19.5108 18.0034C20.0957 18.9258 20.3882 19.9888 20.3882 21.1924C20.3882 22.1373 20.2251 22.9866 19.8989 23.7402C19.5727 24.4826 19.089 25.1182 18.4478 25.6469C17.8066 26.1756 17.0192 26.5805 16.0856 26.8617C15.1519 27.1317 14.0833 27.2667 12.8797 27.2667Z'
      fill='white'
    />
  </svg>
);
export default LogoCompact;
