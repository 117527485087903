import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { actions, Notification } from '@/store/notification/reducer';

export const useNotification = () => {
  const dispatch = useDispatch<AppDispatch>();

  const displayNotification = (notification: Notification) => {
    dispatch(actions.addNotification(notification));
  };

  const clearNotification = (id: string) => {
    dispatch(actions.clearNotification(id));
  };

  return { clearNotification, displayNotification } as const;
};
