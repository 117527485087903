import { api } from './index';
import { GrantType, ValueOf } from '@/api/types';

export const AUTH_SESSION_KEY = 'auth-session';

export interface Auth {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
}

export interface AuthResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
}

export interface LoginRequest {
  grant_type: ValueOf<GrantType>;
  password: string;
  username: string;
}

export interface RefreshTokenRequest {
  grant_type: ValueOf<GrantType>;
  refresh_token: string;
}

export interface ForgetPasswordRequest {
  identifier: string;
  reset_type: string;
}

export interface CheckCode {
  expiresAt: string;
  resetCode: string;
  resetType: string;
  userIdentifier: string;
}

export interface CheckCodeResponse {
  expires_at: string;
  reset_code: string;
  reset_type: string;
  user_identifier: string;
}

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    checkCode: builder.query<CheckCode, string>({
      query: (code) => ({
        url: `/reset-password/${code}`,
      }),
      transformResponse: (response: { data: CheckCodeResponse }) => {
        return {
          expiresAt: response.data.expires_at,
          resetCode: response.data.reset_code,
          resetType: response.data.reset_type,
          userIdentifier: response.data.user_identifier,
        };
      },
    }),
    forgetPassword: builder.mutation<void, ForgetPasswordRequest>({
      query: (requestData) => ({
        body: requestData,
        method: 'POST',
        url: '/forget-password',
      }),
    }),
    login: builder.mutation<Auth, LoginRequest>({
      invalidatesTags: ['User', 'Organization', 'Events'],
      query: (credentials) => ({
        body: credentials,
        method: 'POST',
        url: '/login',
      }),
      transformResponse: (response: { data: AuthResponse }) => {
        return {
          accessToken: response.data.access_token,
          expiresIn: response.data.expires_in,
          refreshToken: response.data.refresh_token,
        } as Auth;
      },
    }),
    logout: builder.mutation<void, void>({
      invalidatesTags: ['User', 'Organization', 'Events'],
      query: () => ({
        body: {},
        method: 'POST',
        url: '/logout',
      }),
    }),
    resetPassword: builder.mutation<void, { code: string; password: string; passwordConfirmation: string }>({
      query: (request) => ({
        body: {
          password: request.password,
          password_confirmation: request.passwordConfirmation,
        },
        method: 'POST',
        url: `/reset-password/${request.code}`,
      }),
    }),
  }),
});
export const {
  useCheckCodeQuery,
  useLoginMutation,
  useLogoutMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
} = authApi;
