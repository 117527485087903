import { combineReducers } from '@reduxjs/toolkit';

import { reducer as authReducer } from './auth/reducer';
import { reducer as notificationReducer } from './notification/reducer';
import { api } from '@/api';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  notifications: notificationReducer,
});
export default rootReducer;
