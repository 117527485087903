import { CacheManager } from './types';

export const setupCacheManager = (): CacheManager => {
  const save = <T>(key: string, data: T) => {
    localStorage.setItem(key, JSON.stringify(data));
  };
  const load = <T>(key: string) => {
    const rawData = localStorage.getItem(key);
    if (rawData) {
      return JSON.parse(rawData) as T;
    }
    return null;
  };
  const remove = (key: string) => {
    localStorage.removeItem(key);
  };

  return { load, remove, save };
};

const cacheManager = setupCacheManager();
export default cacheManager;
