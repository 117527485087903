import { List } from '@mui/material';
import MenuItems from './MenuItems';
import NavItem from './NavItem';

const Items = () => {
  return (
    <List component='nav'>
      {MenuItems.map((item) => (
        <NavItem key={item.id} label={item.label} link={item.link} icon={item.icon} />
      ))}
    </List>
  );
};
export default Items;
