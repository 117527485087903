const LogoBig = () => (
  <svg width='210' height='49' viewBox='0 0 210 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M27.1403 27.0573H21.5385V24.4758H27.1403C28.1752 24.4758 29.0132 24.3295 29.6544 24.0371C30.2956 23.7333 30.768 23.3228 31.0717 22.8053C31.3754 22.2879 31.5273 21.6917 31.5273 21.0168C31.5273 20.3756 31.3754 19.7794 31.0717 19.2283C30.768 18.6771 30.2956 18.2327 29.6544 17.8953C29.0132 17.5466 28.1752 17.3722 27.1403 17.3722H22.3821V34.4477H19.2606V14.7906H27.1403C28.7489 14.7906 30.11 15.0606 31.2236 15.6006C32.3484 16.1292 33.1977 16.8604 33.7714 17.794C34.3563 18.7164 34.6488 19.7794 34.6488 20.983C34.6488 21.9279 34.4857 22.7772 34.1595 23.5309C33.8333 24.2733 33.3496 24.9088 32.7084 25.4375C32.0672 25.9662 31.2798 26.3712 30.3462 26.6524C29.4125 26.9223 28.3439 27.0573 27.1403 27.0573Z'
      fill='white'
    />
    <path
      d='M46.0043 17.541L39.3058 34.4477H36.1168L44.1989 14.7906H46.2406L46.0043 17.541ZM51.7918 34.4477L45.1438 17.4735L44.9245 14.7906H46.983L54.9639 34.4477H51.7918ZM51.3362 27.1754V29.7401H39.6601V27.1754H51.3362Z'
      fill='white'
    />
    <path
      d='M74.0979 14.7906V34.4477H70.9764L60.7851 19.6669V34.4477H57.6636V14.7906H60.7851L70.9764 29.5714V14.7906H74.0979Z'
      fill='white'
    />
    <path
      d='M92.3545 31.883V34.4477H81.1508V31.883H92.3545ZM81.9101 14.7906V34.4477H78.7886V14.7906H81.9101ZM90.8865 23.0078V25.5556H81.1508V23.0078H90.8865ZM92.2533 14.7906V17.3722H81.1508V14.7906H92.2533Z'
      fill='white'
    />
    <path
      d='M108.586 31.883V34.4477H97.9901V31.883H108.586ZM98.7494 14.7906V34.4477H95.6279V14.7906H98.7494Z'
      fill='white'
    />
    <path
      d='M115.299 29.6814L119.484 14.7151H121.66L120.884 18.8827L116.362 34.3721H114.236L115.299 29.6814ZM111.52 14.7151L114.878 29.3946L115.586 34.3721H113.443L108.415 14.7151H111.52ZM126.824 29.3608L130.148 14.7151H133.252L128.224 34.3721H126.081L126.824 29.3608ZM122.268 14.7151L126.385 29.732L127.431 34.3721H125.305L120.918 18.8827L120.125 14.7151H122.268Z'
      fill='#FFCB08'
    />
    <path
      d='M152.69 23.9952V25.1088C152.69 26.5937 152.487 27.9266 152.083 29.1077C151.678 30.2776 151.098 31.2787 150.345 32.1111C149.591 32.9323 148.685 33.5622 147.628 34.0009C146.582 34.4284 145.412 34.6421 144.118 34.6421C142.847 34.6421 141.683 34.4284 140.626 34.0009C139.58 33.5622 138.668 32.9323 137.892 32.1111C137.127 31.2787 136.537 30.2776 136.121 29.1077C135.704 27.9266 135.496 26.5937 135.496 25.1088V23.9952C135.496 22.5104 135.704 21.1831 136.121 20.0132C136.537 18.8321 137.122 17.8253 137.875 16.9929C138.64 16.1605 139.546 15.5306 140.592 15.1031C141.649 14.6644 142.814 14.4451 144.085 14.4451C145.39 14.4451 146.565 14.6644 147.611 15.1031C148.669 15.5306 149.574 16.1605 150.328 16.9929C151.093 17.8253 151.678 18.8321 152.083 20.0132C152.487 21.1831 152.69 22.5104 152.69 23.9952ZM149.602 25.1088V23.9615C149.602 22.8479 149.478 21.8636 149.231 21.0087C148.983 20.1538 148.624 19.4339 148.151 18.849C147.679 18.264 147.099 17.8197 146.413 17.516C145.727 17.2123 144.951 17.0604 144.085 17.0604C143.252 17.0604 142.499 17.2123 141.824 17.516C141.149 17.8197 140.569 18.264 140.086 18.849C139.613 19.4339 139.248 20.1538 138.989 21.0087C138.73 21.8636 138.601 22.8479 138.601 23.9615V25.1088C138.601 26.2225 138.73 27.2123 138.989 28.0785C139.248 28.9334 139.619 29.6589 140.103 30.2551C140.586 30.84 141.166 31.2844 141.841 31.5881C142.527 31.8918 143.286 32.0436 144.118 32.0436C144.996 32.0436 145.772 31.8918 146.447 31.5881C147.122 31.2844 147.696 30.84 148.168 30.2551C148.64 29.6589 148.995 28.9334 149.231 28.0785C149.478 27.2123 149.602 26.2225 149.602 25.1088Z'
      fill='#FFCB08'
    />
    <path
      d='M156.655 14.7151H163.843C165.035 14.7151 166.098 14.8501 167.032 15.12C167.966 15.3787 168.753 15.7612 169.394 16.2674C170.035 16.7736 170.519 17.3979 170.845 18.1403C171.183 18.8827 171.351 19.7376 171.351 20.705C171.351 21.6724 171.155 22.5329 170.761 23.2866C170.378 24.029 169.822 24.6589 169.09 25.1763C168.371 25.6825 167.51 26.0706 166.509 26.3406L165.581 26.7961H158.933L158.899 24.2314H163.927C164.883 24.2314 165.677 24.0852 166.306 23.7927C166.936 23.489 167.409 23.0841 167.724 22.5779C168.05 22.0605 168.213 21.4811 168.213 20.84C168.213 20.1313 168.061 19.5126 167.758 18.9839C167.454 18.4553 166.976 18.0447 166.323 17.7522C165.682 17.4485 164.855 17.2966 163.843 17.2966H159.793V34.3721H156.655V14.7151ZM168.888 34.3721L163.826 25.615L167.116 25.5982L172.246 34.2034V34.3721H168.888Z'
      fill='#FFCB08'
    />
    <path
      d='M181.07 34.3721H176.616L176.633 31.8074H181.07C182.521 31.8074 183.725 31.5375 184.681 30.9975C185.649 30.4576 186.368 29.687 186.841 28.6859C187.313 27.6735 187.55 26.4756 187.55 25.092V23.9783C187.55 22.9097 187.415 21.9592 187.145 21.1268C186.875 20.2944 186.475 19.597 185.947 19.0346C185.418 18.4609 184.76 18.0278 183.972 17.7353C183.196 17.4429 182.296 17.2966 181.273 17.2966H176.515V14.7151H181.273C182.668 14.7151 183.939 14.9344 185.086 15.3731C186.245 15.8006 187.24 16.4249 188.073 17.246C188.905 18.0559 189.546 19.0346 189.996 20.1819C190.446 21.318 190.671 22.5948 190.671 24.0121V25.092C190.671 26.9705 190.272 28.6072 189.473 30.002C188.686 31.3968 187.572 32.4767 186.132 33.2416C184.704 33.9953 183.016 34.3721 181.07 34.3721ZM178.32 14.7151V34.3721H175.199V14.7151H178.32Z'
      fill='#FFCB08'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.41618 37.6706H0V49.0001H11.3295V47.584H1.41618V37.6706Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M198.55 47.5839V49H209.879V37.6706H208.463V47.5839H198.55Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.3309 1.41621V3.05176e-05L0.00145531 3.05176e-05L0.00145531 11.3295H1.41764L1.41764 1.41621L11.3309 1.41621Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M208.463 11.3295H209.879V-9.53674e-06L198.55 -9.53674e-06V1.41615L208.463 1.41615V11.3295Z'
      fill='white'
    />
  </svg>
);
export default LogoBig;
