import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Page404 = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <Typography variant='h1'>404</Typography>
      <Typography variant='h6'>Strona nie istnieje.</Typography>
      <Button variant='contained' onClick={() => navigate(-1)}>
        Powrót
      </Button>
    </Box>
  );
};
export default Page404;
