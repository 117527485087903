export type ValueOf<T> = T[keyof T];
export enum PaymentStatus {
  CANCELLED = 'CANCELLED',
  CONFIRMED = 'CONFIRMED',
  PENDING = 'PENDING',
  WAITING_WITHOUT_RESERVATION = 'WAITING_WITHOUT_RESERVATION',
  WAITING_WITH_RESERVATION = 'WAITING_WITH_RESERVATION',
}
export enum Status {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  SCHEDULED = 'SCHEDULED',
}
export enum ParticipantSource {
  IFRAME = 'IFRAME',
  PANEL = 'PANEL',
}
export enum EventTypeTags {
  SUBSTANCE_ABUSE = 'substance_abuse',
}
export enum GrantType {
  PASSWORD = 'password',
  REFRESH_TOKEN = 'refresh_token',
}
