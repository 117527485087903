import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Page404 from '@/pages/Page404';
import { dashboardRoutes, mainRoutes } from '@/routes';
import DashboardLayout from '@/components/Layout/DashboardLayout';
import MainLayout from '@/components/Layout/MainLayout';
import ProtectedRoute from '@/routes/ProtectedRoute';

function App() {
  const router = createBrowserRouter([
    {
      children: dashboardRoutes,
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      errorElement: <Page404 />,
    },
    {
      children: mainRoutes,
      element: <MainLayout />,
      errorElement: <Page404 />,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
