import { Suspense, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, CssBaseline, Toolbar } from '@mui/material';
import AppBar from '../AppBar';
import ToolBar from '../ToolBar';
import Loader from './Loader';
import Sidebar from '../Sidebar';

const DashboardLayout = () => {
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <>
      <CssBaseline />
      <Box sx={{ display: 'flex' }}>
        <AppBar open={open} color='default'>
          <ToolBar toggleDrawer={toggleDrawer} open={open} />
        </AppBar>
        <Sidebar open={open} />
        <Box
          component='main'
          sx={{
            backgroundColor: (theme) => theme.palette.grey[100],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
    </>
  );
};
export default DashboardLayout;
