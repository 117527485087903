import { createTheme } from '@mui/material/styles';
export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      dark: '#0E0F22',
      light: '#43445A',
      main: '#141631',
    },
    secondary: {
      dark: '#B28E05',
      light: '#FFD539',
      main: '#FFCB08',
    },
  },
});
