import { Outlet, useNavigate } from 'react-router-dom';
import navigationItems from './navigationItems';
import { useAppSelector } from '@/hooks/hooks';
import React, { useEffect } from 'react';
import { selectIsLoggedIn } from '@/store/auth/reducer';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(navigationItems.LOGIN.path, { replace: true });
    }
  }, [isLoggedIn]);

  return children ? children : <Outlet />;
};
export default ProtectedRoute;
