import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './App';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './utils/themes';
import Notification from './components/Notification';

if (import.meta.env.DEV && import.meta.env.VITE_USE_MOCKS === 'true') {
  const { worker } = await import('./mocks/browser');
  console.log('Worker MSW start');
  worker.start();
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  // FYI - disabled strict mode because of doubled renderings
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
      <Notification />
    </ThemeProvider>
  </Provider>,
  // </React.StrictMode>,
);
