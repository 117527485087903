import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import React, { useState } from 'react';
import { useAppSelector } from '@/hooks/hooks';
import { selectIsLoggedIn } from '@/store/auth/reducer';
import { Link } from 'react-router-dom';
import navigationItems from '@/routes/navigationItems';
import { Skeleton } from '@mui/material';
import { useGetUserDetailsQuery } from '@/api/user';
import MenuItems from './MenuItems';

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data, isLoading } = useGetUserDetailsQuery();

  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  // const user = useAppSelector(selectUser);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isLoading) {
    return <Skeleton variant='text' width={230} sx={{ fontSize: '1rem' }} />;
  }

  return (
    <div>
      <Button
        sx={{ '&:hover': { backgroundColor: '#0000000a' } }}
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <AccountCircleIcon sx={{ m: 1 }} />
        <Typography sx={{ ml: 1, mr: 1 }}>{data?.email || 'Moje konto'}</Typography>
        {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={handleClose}
        onClose={handleClose}
      >
        {isLoggedIn && (
          <div>
            {MenuItems.map((item) => (
              <MenuItem key={item.id} component={Link} to={item.link}>
                {item.label}
              </MenuItem>
            ))}
          </div>
        )}

        {!isLoggedIn && (
          <MenuItem component={Link} to={navigationItems.LOGIN.path}>
            Zaloguj
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
export default AccountMenu;
