import React from 'react';
import navigationItems from './navigationItems';

const ScheduledEvents = React.lazy(() => import('../pages/ScheduledEvents'));
const Calendar = React.lazy(() => import('../pages/Calendar'));
const Participants = React.lazy(() => import('../pages/Participants'));
const Login = React.lazy(() => import('../pages/Login'));
const Logout = React.lazy(() => import('../pages/Logout'));
const Settings = React.lazy(() => import('../pages/Settings'));
const ForgotPassword = React.lazy(() => import('../pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../pages/ResetPassword'));
const StaffMembers = React.lazy(() => import('../pages/StaffMembers'));
const ParticipantsAll = React.lazy(() => import('../pages/ParticipantsAll'));

const dashboardRoutes = [
  { element: <ScheduledEvents />, path: navigationItems.HOME.path },
  { element: <Calendar />, path: navigationItems.GET_CALENDAR.path },
  { element: <Participants />, path: navigationItems.PARTICIPANTS.path },
  { element: <Settings />, path: navigationItems.SETTINGS.path },
  { element: <StaffMembers />, path: navigationItems.USERS.path },
  { element: <ParticipantsAll />, path: navigationItems.PARTICIPANTS_ALL.path },
];
const mainRoutes = [
  { element: <Login />, path: navigationItems.LOGIN.path },
  { element: <ForgotPassword />, path: navigationItems.FORGOT_PASSWORD.path },
  { element: <ResetPassword />, path: navigationItems.PASSWORD_RESET.path },
  { element: <Logout />, path: navigationItems.LOGOUT.path },
];

export { mainRoutes, dashboardRoutes };
