import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cacheManager from '@/utils/cache/cacheManager';
import { RootState } from '../store';
import { Auth, AUTH_SESSION_KEY } from '@/api/auth';

interface AuthState {
  accessToken: string | null;
  expiresIn: number | null;
  refreshToken: string | null;
}

const initialStateData: AuthState = {
  accessToken: null,
  expiresIn: null,
  refreshToken: null,
};

const authSessionData: AuthState | null = cacheManager.load(AUTH_SESSION_KEY)
  ? (cacheManager.load(AUTH_SESSION_KEY) as AuthState)
  : initialStateData;

const initialState: AuthState = {
  ...authSessionData,
};

const slice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    loginSuccess: (state: AuthState, action: PayloadAction<Auth>) => ({
      ...state,
      ...action.payload,
    }),
    logout: () => initialStateData,
  },
});

export const selectIsLoggedIn = (state: RootState) => !!state.auth.accessToken;

const { name, reducer, actions } = slice;

export { name, actions, reducer };
